<template>
    <div class="title">
      <span class="title_text">小米-当日签到奖励</span>
    </div>
    <el-row class="mb-4">
      <el-button  :type="search.day=='yesteday'?'primary':'Default'" @click="dayChange('yesteday')">昨天</el-button>
      <el-button  :type="search.day=='today'?'primary':'Default'"  @click="dayChange('today')">今天</el-button>
      <el-button  :type="search.day=='tomrrow'?'primary':'Default'"  @click="dayChange('tomrrow')">明天</el-button>
    </el-row>
    <ElConfigProvider :locale="locale">
      <el-table :data="pageData.list" border="true" height="800px"  class="el-table">
        <el-table-column prop="tradeId" label="序号" width="70px">
          <template v-slot="scop">
            {{scop.$index+1}}
          </template>
        </el-table-column>
        <el-table-column prop="rewardType" label="奖励类型"  width="100px"/>
        <el-table-column prop="rewardName" label="奖励内容" width="120px"/>
        <el-table-column prop="rewardQuantity" label="奖励份额" width="100px" />
        <el-table-column prop="url" label="活动地址"/>
        <el-table-column prop="days" label="签到天数" width="100px"/>
      </el-table>
      <el-pagination
        :total="pageData.total"
        :current-page="pageData.currentPage"
        :page-size="pageData.pageSize"
        layout="total,prev, pager,"
        @current-change="handlePageChange($event)"
        background="--el-color-primary"
        class="pageEl"
      ></el-pagination>
    </ElConfigProvider>
  </template>

  <script setup lang="ts">

  import { get } from '@/utils/request'
  import { ElButton } from 'element-plus'
  import zhLocale from 'element-plus/es/locale/lang/zh-cn'
  import { ref } from 'vue'

  const locale = ref(zhLocale)
  const pageData = ref({
    list: null,
    total: 0,
    currentPage: 1,
    pageSize: 20
  })
  const search = ref({
    day: 'tomrrow'
  })
  type DayMap = {
    [key: string]: string;
  }
  const now = new Date()
  const today = now.getTime()
  console.log(today)
  const yesterday = new Date().setDate(now.getDate() - 1)
  const tomorrow = new Date().setDate(now.getDate() + 1)
  console.log(tomorrow)
  console.log(yesterday)


  const dayMap:DayMap = {
    yesteday: timestampToTime(yesterday),
    today: timestampToTime(today),
    tomrrow: timestampToTime(tomorrow)
  }
  async function dayChange (day: string) {
    search.value.day = day
    const searchDay = dayMap[day]
    getSignList(searchDay, pageData.value.currentPage, pageData.value.pageSize)
  }

  async function handlePageChange (newPage: number) {
    pageData.value.currentPage = newPage
    const searchDay = dayMap[search.value.day]
    getSignList(searchDay, newPage, pageData.value.pageSize)
  }
  async function getSignList (searchDay:string, pageNumber: number, pageRow: number) {
    const res = await get(`/kafuFour/signReward/page?pageNum=${pageNumber}&pageRow=${pageRow}&signDay=${searchDay}`) // &signDay=2023-11-30
    pageData.value.list = res.list
    pageData.value.total = res.total
  }

  function timestampToTime (timstap:number) {
    const date = new Date(timstap)
    console.log(date)
    const Y = date.getFullYear() + '-'
    console.log(Y)
    const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
    const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ''
    // const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
    // const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    return Y + M + D
  }

  getSignList('', pageData.value.currentPage, pageData.value.pageSize)
  </script>
  <style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  .pageEl{
    float: right;
      margin-right: 0px;
      margin-top: 4px;
  }
  .el-table {
      background-color: #e6f1fc;
      border-color: #dae2f4;
  }
  .title{
    width: auto;
      text-align: left;
      padding-left: 3%;
      border-bottom: 1px solid #927b7b;
      padding-bottom: 8px;
  }
  .title_text{
    min-width: 100px;
      display: flex;
      align-items: center;
      font-size: 18px;
      line-height: 100%;
      color: #1989fa;
  }
  .search_div{
    height: 20px;
  }
  nav {
    padding: 30px;
  }

  nav a {
    font-weight: bold;
    color: #2c3e50;
  }

  nav a.router-link-exact-active {
    color: #42b983;
  }
  .mb-4{
      height: 50px;
      padding-top: 10px;
      float: right;
  }
  </style>
