<template>
  <div class="vmcms">
    <div class="powerful_code-main">
      <div class="page-app active page">
        <div class="page-phone" style="background: rgb(255, 255, 255)">
          <div class="page-render page">
            <!-- <div
              class="page-title"
              style="
                background: none;
                border: 1px solid rgb(255, 0, 0);
                color: rgb(255, 0, 0);
              "
            >
              {{ title }}
            </div> -->
            <div
              data-v-149e9d2f=""
              data-v-6373e7dc=""
              class="banner-img active"
            >
              <img
                data-v-149e9d2f=""
                src="https://img-haodanku-com.cdn.fudaiapp.com/FjZCNgjJWUvyR8rFPRst1skQPRSM_600"
                alt=""
                class="bg"
              />
            </div>
            <div class="rich-text-box-wrapper">
              <div class="editor-content-view">
                <p style="line-height: 2; margin: 15px 0;">
                  <span style="font-size: 19px"><strong>↓直接点</strong></span>
                </p>
              </div>
            </div>
            <div v-for="text in texts" :key="text">
              <button
                class="coppy_button"
                @click="copyText(text.title+'\n'+text.value)"
                style="  background: linear-gradient(
                    90deg,
                    rgb(251, 136, 77) 0%,
                    rgb(247, 62, 40) 100%
                  );
                "
              >
                {{ text.title }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";
import clipboard3 from "vue-clipboard3";

import { get } from "@/utils/request";
export default defineComponent({
  name: "HelloWorld",
  props: {
    msg: String,
  },
  data() {
    return {
      texts: [], // 列表文本
      title: "撸1豆-京豆裙 微信搜：u881001",
      encData: "", // 获取动态参数的值
    };
  },
  created() {
    document.title = "鲁1豆- 微信搜：u881001"; // 将标题修改为指定值
  },
  mounted() {
    this.encData = (this.$route.query.encData as string)
      ? (this.$route.query.encData as string)
      : ""; // 获取动态参数的值
    console.log(this.encData);
    this.getSignList(this.encData);
  },
  methods: {
    getSignList(encData: string) {
      get(`/kafuFour/jdUrl/lu1dou/info?encData=${encData}`)
        .then((res) => {
          console.log("res",res);
          this.title = res.title;
          this.texts = res;
        })
        .catch((error) => {
          console.error("Error:", error);
        }); // &signDay=2023-11-30
    },
    isMobile() {
      const userAgent = navigator.userAgent;
      const mobileKeywords = ['Mobile', 'Android', 'iPhone', 'iPad', 'Windows Phone'];

      for (let i = 0; i < mobileKeywords.length; i++) {
        if (userAgent.indexOf(mobileKeywords[i]) !== -1) {
          return true;
        }
      }
      return false;
    },
    copyText(text: string) {


      //解构出复制方法
      const { toClipboard } = clipboard3();
      try {
          let alertMessage = '一键复制 打开京東app查看';
          toClipboard(text);
          let urls = text.match(/http.+?\s/)
            if (urls && urls.length>=0 && urls[0].includes("tb.cn")) {
                  alertMessage = '一键复制 打开淘宝app查看';
            }
          ElMessage({
            message: "<span  style='--el-message-text-color: #f5f5f5; color:#f5f5f5;'>"+alertMessage+"<span>",
            type: "success",
            customClass:"msgInfo",
            dangerouslyUseHTMLString : true
          });
          if(this.isMobile()){
            console.log("当前移动端")
            // 将 loc 赋值给 window.location.href
            if (urls && urls.length>=0 && urls[0].includes("m.tb.cn")) {
              location.href = urls[0].replace("m.tb.cn", "s.tb.cn").replace(/\s/g, "");
            }
          }
      } catch (error) {
        alert("复制失败!!")
      }

    },
  },
});
</script>
<style scoped>
.powerful_code-main {
  max-width: 750px;
  margin: auto;
  box-shadow: 0 0 10px 10px #f5f5f5;
  min-height: 100vh;
}
html,
body {
  font-size: 14px;
  line-height: 1.15;
}
* {
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: transparent;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.page-title {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
}
.rich-text-box {
  word-wrap: break-word;
  min-height: 44px;
  outline: 0;
  padding: 0;
  white-space: pre-wrap;
  text-align: left;
  border-width: 1px;
}
.rich-text-box-wrapper {
  margin-top: 10px;
  border-width: 1px;
  border-color: rgb(255, 76, 66);
  border-style: dashed;
}
.w-e-text-container {
  background-color: var(--w-e-textarea-bg-color);
  color: var(--w-e-textarea-color);
  height: 100%;
  text-align: center;
  position: relative;
}
.rich-text-box {
  word-wrap: break-word;
  min-height: 44px;
  outline: 0;
  padding: 0;
  white-space: pre-wrap;
  text-align: left;
  border-width: 1px;
}
.coppy_button {
  margin-top: 10px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(#f73e28),
    to(#fb884d)
  );
  background: linear-gradient(270deg, #f73e28 0%, #fb884d 100%);
  border-radius: 3px;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  border: 0;
  cursor: pointer;
}
.active .page {
  background: rgb(255, 255, 255);
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  transform: none;
  width: 100%;
}
.page-app {
  display: block;
  border: none;
  outline: 1px solid #e8e8e8;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: white;
}
.page-render {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px;
  height: 100%;
  display: block;
}
.page-app.active {
    height: 100vh;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
}
.banner-img img {
    width: 100%;
    display: block;
}
::v-deep .el-message .el-message__content {
  background-color: black !important;
  color: white !important;
}
.custom_class {
  --el-message-text-color: #f5f5f5;
}
</style>
